jQuery( function ( $ ) {

    $('section.glossary').each( function () {

        let active_desktop_letter = null;

        let $letter_selectors = $( '.letter-select', this );
        let $accordion_group = $( '.accordion-group', this );
        let $accordion_items = $( '.accordion-item', this );


        // set default active
        function set_desktop_default_active () {
            if ( !active_desktop_letter ) {
                let $active = $letter_selectors.filter( '.active' );
                if ( $active.length ) {
                    active_desktop_letter = $active.first().data().letter;
                } else {
                    active_desktop_letter = $letter_selectors.first().addClass( 'active' ).data().letter;
                }
            }
            $letter_selectors.filter( `:not([data-letter=${ active_desktop_letter }])` ).removeClass( 'active' );
        }


        function toggle_autocollapse () {
            $accordion_group.toggleClass( 'autocollapse', ThemeJS.Media_Queries.is_at_least( 'md' ) );
        }
        toggle_autocollapse();


        // listeners for accordion events
        $accordion_items.on( 'accordion-item-opened', function () {
            $letter_selectors.filter( `[data-letter=${ $(this).data().letter }]` ).addClass( 'active' );
            active_desktop_letter = $(this).data().letter;
        });
        $accordion_items.on( 'accordion-item-closed', function () {
            $letter_selectors.filter( `[data-letter=${ $(this).data().letter }]` ).removeClass( 'active' );
        });


        // trigger accordion open on click
        $letter_selectors.on( 'click', function () {
            $accordion_items.filter( `[data-letter=${ $(this).data().letter }]` ).accordion_open();
        });


        // toggle autocollapse and trigger default open
        $( window ).on( 'breakpoint_changed', function ( e, to, from ) {
            toggle_autocollapse();
            if ( to == 'md' && from == 'sm' ) {
                // switching to desktop
                set_desktop_default_active();
                $accordion_items.filter( `[data-letter=${ active_desktop_letter }]` ).accordion_open();
            } else if ( to == 'sm' && from == 'md' ) {
                // switching to mobile
                $accordion_items.filter( '.open' ).find( '.accordion-content' ).css( 'display', 'block' );
            }
        });

    });

});