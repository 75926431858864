jQuery( function ( $ ) {

    let cached_results = {};

    $('section.team_members_archive').each( function () {
        
        let $results_wrap = $( '.results-wrap', this );
        let $category_links = $( '.filter-wrap button' );
        let $category_select = $( '.filter-wrap select' );

        cache_current_category();

        $category_links.click( function () {
            display_category( $(this).data().term_id );
        });

        $category_select.select2({
            minimumResultsForSearch: -1
        });
        $category_select.change( function () {
            display_category( $(this).val() );
        })

        function display_category ( term ) {
            $category_links.removeClass( 'active' ).filter( `[data-term_id=${ term }]` ).addClass( 'active' );
            $category_select.val( term ).trigger( 'change.select2' );
            if ( cached_results.hasOwnProperty( term ) ) {
                transition_content( cached_results[ term ] );
            } else {
                $results_wrap.loading_screen();
                load_category( term );
            }
        }

        function transition_content ( new_content ) {
            let $old_results = $results_wrap.find( '.results' );
            let $new_results = $( new_content ).addClass( 'temp' );
            $results_wrap.append( $new_results );
            $results_wrap.height( $results_wrap.height() );
            $results_wrap.height( $new_results.height() );
            $old_results.addClass('temp');
            setTimeout( function () {
                $old_results.remove();
                $new_results.removeClass( 'temp' );
                $results_wrap.css( 'height', 'auto' );
                $results_wrap.loading_screen( false );
            }, 300 );
        }

        function load_category( term ) {
            ThemeJS.Functions.send_ajax_request({
                data: {
                    'action': 'get_bao_team_members_by_category',
                    'category': term
                },
                success: function ( response ) {
                    cached_results[ term ] = response.results;
                    display_category( term );
                }
            });
        }

        function cache_current_category () {
            let $results = $results_wrap.find( '.results' );
            if ( $results.length ) {
                let term_id = $results.attr( 'data-term' );
                if ( !cached_results.hasOwnProperty( term_id ) ) {
                    cached_results[ term_id ] = $results_wrap.html();
                }
            }
        }

    });

});