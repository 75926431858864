jQuery( function ( $ ) {

    $( 'section.posts_slider .slick-slider' ).each( function () {

        let $section = $( this ).closest( 'section' );
        let $live_section = $( '.slider-text-live', $section );

        // js reads in sec, so converting to ms
        let initial_transition_duration = parseInt( 1000 * parseFloat( $( '.slick-slide', this ).first().css( 'transition-duration' ) ) );

        $(this).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            arrows: true,
            prevArrow: $( '.prev-btn', $section ),
            nextArrow: $( '.next-btn', $section ),
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 1260,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4
                    }
                }
            ]
        });

        $(this).on( 'beforeChange', function ( e, slick, current, next ) {
            let slides_to_show = slick.getOption( 'slidesToShow' );
            let speed = slick.getOption( 'speed' );
            let current_edge = ( current > next ) ? current : ( current + slides_to_show );
            slick.$slides.each( function () {
                let this_index = parseInt( $(this).attr('data-slick-index') );
                let slides_off = Math.abs( this_index - current_edge );
                if ( this_index < current_edge ) {
                    slides_off--;
                }
                let delay = ( slides_off % slides_to_show ) * speed / slides_to_show;
                $(this).css({
                    'transition-duration': `${ speed }ms`,
                    'transition-delay': `${ delay }ms`
                });
            });
        });
    
    
        $(this).on( 'setPosition', function ( e, slick, current ) {
            slick.$slides.each( function () {
                $(this).css({
                    'transition-duration': `${ initial_transition_duration }ms`,
                    'transition-delay': '0ms',
                });
            });
        });

        $(this).on( 'afterChange', function ( e, slick, current ) {
            let post_titles = [];
            slick.$slides.filter( '.slick-active' ).each( function ( index ) {
                post_titles.push( `${ index + 1 }: ${ $( 'h3', this ).html() }` );
            });
            $live_section.html( `Active slide titles: ${ post_titles.join( ', ' ) }` );
        });

    });

});