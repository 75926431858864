jQuery( function ( $ ) {

    $('section.text_slider').each( function () {

        let $slider = $( '.slick-slider', this );

        $slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            dots: true,
            arrows: false,
            adaptiveHeight: true,
        });

        $slider.on( 'afterChange', function ( e, slick, current ) {
            $( `.slick-slide[data-slick-index='${ current }']`, $slider ).focus();
        });

    });

});