( function ( $ ) {

    $.fn.extend({
        focus_without_scroll: function() {
            let x = window.scrollX, y = window.scrollY;
            this.focus();
            window.scrollTo( x, y );
            return this;
        }
    });

})( jQuery );