( function ( $ ) {

    $.fn.extend({
        accordion_toggle: function () {
            return this.hasClass( 'open' ) ? this.accordion_close() : this.accordion_open();
        },
        accordion_open: function ( options ) {
            if ( this.hasClass( 'accordion-item' ) ) {
                if ( this.hasClass( 'default-open' ) ) {
                    // set css correctly and remove default-open class
                    this.find( '.accordion-content' ).css( 'display', 'block' );
                    this.addClass( 'open' ).removeClass( 'default-open' );
                } else {
                    this.addClass( 'open' );
                    this.find( '> .accordion-heading' ).attr( 'aria-expanded', 'true' );
                    this.find( '> .accordion-content' ).attr( 'aria-hidden', 'false' ).slideDown( 300 );
                    let $group = $(this).closest( '.accordion-group' );
                    if ( $group.hasClass( 'autocollapse' ) ) {
                        this.siblings().accordion_close();
                    }
                }
                this.trigger( 'accordion-item-opened' );
            }
            return this;
        },
        accordion_close: function () {
            if ( this.hasClass( 'accordion-item' ) && this.hasClass( 'open' ) ) {
                this.removeClass( 'open' );
                this.find( '> .accordion-heading' ).attr( 'aria-expanded', 'false' );
                this.find( '> .accordion-content' ).attr( 'aria-hidden', 'true' ).slideUp( 300 );
                this.trigger( 'accordion-item-closed' );
            }
            return this;
        }
    });
    
    $( document ).on( 'click', '.accordion-heading', function () {
        $(this).closest( '.accordion-item' ).accordion_toggle();
    });

    $(function () {
        $( '.accordion-item.default-open' ).accordion_open();
    });

})( jQuery );