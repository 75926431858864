(function ($) {


    let $header;
    let $header_main_wrap;
    let $sitewide_banner;
    let $body;
    let $menu_wrap;
    let $menu_toggle;
    let $submenus;
    let $submenus_fr;
    let $sub_menu_toggles;
    let $window = $(window);
    let $non_mobile_menu_tabbables;

    let transparent_header = false;

    function set_element_refs() {
        $header = $('header.site-header');
        $header_main_wrap = $('.header-main-wrap', $header);
        $sitewide_banner = $('#simplistics-sitewide-banner', $header);
        $body = $(document.body);
        $menu_wrap = $('.menu-wrap', $header);
        $menu_toggle = $('button.menu-toggle', $header);
        $submenus = $('#menu-primary-menu > li > .sub-menu');
        $submenus_fr = $('#menu-primary-menu-fr > li > .sub-menu');
        $sub_menu_toggles = $('button.sub-menu-toggle', $menu_wrap);
        $non_mobile_menu_tabbables = $('#skip-to-content, .header-top, .header-main .logo-link, .page-banner, .breadcrumbs, .page-title, main, footer');
    }


    function is_mobile() {
        return $menu_toggle.is(':visible');
    }


    function set_opacity() {
        $header.toggleClass('opaque', (!transparent_header || ($window.scrollTop() != 0)));
    }


    function init_header_style() {
        // sets initial styles and listeners based on whether header is loaded as fixed
        if ($header.hasClass('fixed')) {
            $header.addClass('transparent-at-top');
            transparent_header = true;
            set_opacity();
            $window.on('scroll breakpoint_changed', set_opacity);
        }
        set_body_padding();
        $window.on('breakpoint_changed', set_body_padding);
        $header.addClass('fixed');
        $menu_toggle.css('margin-right', ThemeJS.Functions.get_scrollbar_width());
    }


    function set_body_padding(include_banner = true, set = true) {
        let spacing = !transparent_header ? $header.height() : ($sitewide_banner.length ? $sitewide_banner.outerHeight() : 0);
        if (!include_banner && $sitewide_banner.length) {
            spacing = Math.max(spacing - $sitewide_banner.outerHeight(), 0);
        }
        if (set) {
            $body.css({
                paddingTop: `${spacing}px`
            });
        } else {
            return spacing;
        }
    }


    function set_submenu_styles() {
        if ($submenus.length) {
            $submenus.each(function () {
                $(this).css({
                    top: is_mobile() ? 0 : ($(this).parent().hasClass('grouped-subitems') ? `calc( 1rem + ${$header_main_wrap.height() - 1}px )` : `calc( 1rem + 100% )`),
                    display: is_mobile() ? 'none' : 'block',
                });
            });
        }
        if ($submenus_fr.length) {
            $submenus_fr.each(function () {
                $(this).css({
                    top: is_mobile() ? 0 : ($(this).parent().hasClass('grouped-subitems') ? `calc( 1rem + ${$header_main_wrap.height() - 1}px )` : `calc( 1rem + 100% )`),
                    display: is_mobile() ? 'none' : 'block',
                });
            });
        }
    }


    function cache_menu_tabindices() {
        $('a', $menu_wrap).each(function () {
            let index = $(this).attr('tabindex');
            $(this).attr('data-tabindex', index ? index : 0);
        });
    }


    function set_menu_tab_index(tab_index) {
        tab_index = tab_index ?? (!is_mobile() ? 0 : -1);
        $('a', $menu_wrap).each(function () {
            $(this).attr('tabindex', (tab_index == -1) ? -1 : $(this).attr('data-tabindex'));
        });
        let sub_menu_toggle_tab_index = (!is_mobile() || tab_index == -1) ? -1 : 0;
        $sub_menu_toggles.attr('tabindex', sub_menu_toggle_tab_index);
    }

    $(window).on('load', function () {
        set_element_refs();

        init_header_style();
        // setTimeout( init_header_style, 0 );

        set_submenu_styles();
        cache_menu_tabindices();
        set_menu_tab_index();
        $window.on('breakpoint_changed', function (e, to, from) {
            if ([to, from].includes('xl')) {
                $non_mobile_menu_tabbables.css('visibility', 'visible');
                $body.removeClass('menu-open');
            }
            set_submenu_styles();
            set_menu_tab_index();
        });

        $(document).on('simplistics-sitewide-banner-pre-dismiss', function (e, duration) {
            // animate body padding
            $body.css('transition', `padding-top ${duration}ms ease`);
            let spacing = set_body_padding(false, false);
            setTimeout(function () {
                $body.css({
                    paddingTop: `${spacing}px`
                });
            }, 0);
        });
        $(document).on('simplistics-sitewide-banner-dismissed', function () {
            set_submenu_styles();
            $body.css('transtion', 'none');
        });

        $menu_toggle.click(function () {
            $body.toggleClass('menu-open');
            if ($body.hasClass('menu-open')) {
                set_menu_tab_index(0);
                $('a', $menu_wrap).first().focus();
                setTimeout(function () {
                    $non_mobile_menu_tabbables.css('visibility', 'hidden');
                }, 300);
            } else {
                set_menu_tab_index(-1);
                $non_mobile_menu_tabbables.css('visibility', 'visible');
            }
        });
        if ($sub_menu_toggles.length) {
            $sub_menu_toggles.click(function (e) {
                e.preventDefault();
                e.stopPropagation();
                let $parent_li = $(this).closest('li');
                let $submenu = $('> ul.sub-menu', $parent_li);
                let was_visible = $submenu.is(':visible');
                $submenu.slideToggle(300);
                $parent_li.attr('aria-expanded', !was_visible);
            });
        }
    });


})(jQuery);